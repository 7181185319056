const open = (target) => {
  document.querySelectorAll('.menu__itemList').forEach((v) => {
    v.classList.remove('open');
  });
  document.querySelectorAll('.js-menu-trigger').forEach((v) => {
    v.classList.remove('open');
  });
  target.classList.add('open');
};
const close = (target) => {
  target.classList.remove('open');
};

export const prepareGlobalMenu = () => {
  const menuButton = document.querySelector('.menuButton');
  const menu = document.querySelector('.menu');
  const glHeader = document.querySelector('.globalHeader');
  const body = document.querySelector('body');
  let windowScrollY = 0;

  menuButton.addEventListener('click', function () {
    const isOpened = menu.classList.contains('open');

    menu.classList.toggle('open');
    menuButton.classList.toggle('open');
    glHeader.classList.toggle('open');

    if (!isOpened) {
      windowScrollY = window.scrollY;
      body.classList.add('fixed');
      return;
    }

    body.classList.remove('fixed');
    window.scrollTo(0, windowScrollY);
  });

  const menuItems = document.querySelectorAll('.js-menu');
  menuItems.forEach((v) => {
    const trigger = v.querySelector('.js-menu-trigger');

    trigger.addEventListener('click', (ev) => {
      if (ev.currentTarget.classList.contains('open')) {
        ev.currentTarget.classList.remove('open');
        close(ev.currentTarget.nextElementSibling);
        return;
      }
      open(ev.currentTarget.nextElementSibling);
      glHeader.classList.add('open');
      ev.currentTarget.classList.add('open');
    });
  });

  const links = document.querySelectorAll('.js-link');
  links.forEach((link) => {
    link.addEventListener('click', () => {
      const root = document.querySelector('.globalHeader');
      root.querySelectorAll('.open').forEach((v) => {
        v.classList.remove('open');
      });
    });
  });
};
