const onLoaded = () => {
  const header = document.querySelector('.globalHeader');
  const hero = document.querySelector('.topHero');

  if (!header || !hero) {
    return;
  }

  window.addEventListener('scroll', () => {
    if (
      hero.getBoundingClientRect().bottom <
      header.getBoundingClientRect().height
    ) {
      header.classList.remove('--transparent');
      return;
    }

    header.classList.add('--transparent');
  });
};

export const prepareHeaderOnVideo = () => {
  window.addEventListener('DOMContentLoaded', onLoaded);
};
