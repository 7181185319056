const removeMovie = (movie) => {
  if (movie && movie.parentNode) {
    movie.parentNode.removeChild(movie);
  }
};

export const prepareMovieOverlay = () => {
  const movieOpenButtons = document.querySelectorAll('[data-js-movie-open]');
  const movieContainers = document.querySelectorAll('[data-js-movie]');

  const movieEls = Array.from(movieContainers).reduce((output, el) => {
    output[el.dataset.jsMovie] = el;
    return output;
  }, {});

  movieContainers.forEach((v) => {
    v.querySelector('[data-js-movie-close]').addEventListener('click', () => {
      removeMovie(v);
    });

    v.addEventListener('click', () => {
      removeMovie(v);
    });

    v.parentNode.removeChild(v);
  });

  movieOpenButtons.forEach((v) => {
    v.addEventListener('click', (ev) => {
      const lang = ev.target.dataset.jsMovieOpen;
      const movie = movieEls[lang];
      if (!movie) {
        return;
      }

      removeMovie(movie);
      movie.style.opacity = '0';
      document.body.appendChild(movie);

      requestAnimationFrame(() => {
        movie.style.opacity = '1';
      });
    });
  });
};
