const isInnerLink = (href) => {
  if (/^#.*/.test(href)) {
    return true;
  }

  const targetOrigin = href.match(/http[s]?:\/\/[^/]*/)[0];
  const path = href.replace(targetOrigin, '').replace(/#.*/, '');
  return (
    targetOrigin === window.location.origin && path === window.location.pathname
  );
};

const scrollTo = (target) => {
  const globalHeader = document.querySelector('.globalHeader');
  const targetTop = target.getBoundingClientRect().top + window.pageYOffset;

  const scrollY = targetTop - globalHeader.getBoundingClientRect().height;

  window.scrollTo({
    top: scrollY < 0 ? 0 : scrollY,
    behavior: 'smooth',
  });
};

export const setSmoothScroll = () => {
  const innerLinkNodes = document.querySelectorAll('a');
  Array.from(innerLinkNodes)
    .filter((v) => isInnerLink(v.href))
    .forEach((v) => {
      v.addEventListener('click', (ev) => {
        ev.preventDefault();
        const matches = v.href.match(/#.*$/);
        if (!matches) {
          scrollTo(document.querySelector('body'));
          window.history.pushState(null, null, window.location.pathname);
          return;
        }

        const hash = matches[0];
        const target = document.querySelector(hash);

        if (!target) {
          return;
        }

        scrollTo(target);

        if (hash === '#top') {
          window.history.pushState(null, null, window.location.pathname);
          return;
        }

        window.history.pushState(
          null,
          null,
          `${window.location.pathname}${hash}`
        );
      });
    });
};
